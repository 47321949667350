import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Typography, Stack } from '@mui/material';
import { useTheme } from '@emotion/react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import LoginForm from './LoginForm';

const Register = ({ setEmail, setPassword, handleAction }) => {
  const { palette } = useTheme();
  const deepPurple = palette.primary.main;

  return (
    <>
      <Navbar />
      <Stack
        alignItems="center"
        justifyContent="center"
        bgcolor={deepPurple}
        ml='auto'
        mr='auto'
        height="100"
        width="100%"
      >
        <Typography sx={{
          ml: { xl: "6ch", xs: "4ch" },
          mr: { xl: '6ch', xs: '4ch' },
          mt: '4ch',
          mb: '2%',
          fontSize: {
            xl: '1.5em',
            md: '1.5em',
            sm: '1.5em',
            xs: '1em'
          }
        }}
          fontFamily="Quicksand"
          color={'#fff'}
          align='center'
          fontWeight={500}
        >
          Login to OrchidWiki to start contributing!
        </Typography>

        {/* <img src='../../assets/4.png' width="15%" alt='orchid' /> */}

        {/* LOGIN FORM */}
        <Stack
          display="flex"
          bgcolor="#fff"
          mt='2ch'
          sx={{
            width: "350px",
            height: "450px",
          }}
        >
          <Typography
            mt="2ch"
            mb="1ch"
            fontSize="1.5em"
            fontFamily="Quicksand"
            fontWeight={500}
            color={deepPurple}
            align='center'>
            Login
          </Typography>
          <LoginForm
            setEmail={setEmail}
            setPassword={setPassword}
            handleAction={handleAction}
          />
        </Stack>
        <Typography
          fontFamily="Quicksand"
          fontSize="1rem"
          color="#fff"
          mt="6em"
          mb="7em"
          fontWeight={500}
        >
          Forgot password? <NavLink className="darkbg-link" to="/forgotpassword">Reset here.</NavLink>
        </Typography>
      </Stack>
      <Footer />
    </>
  )
}

export default Register;