import React, { useState } from 'react';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

const SearchOrchids = ({ setOrchids }) => {
  const { palette } = useTheme();
  const mediumPurple = palette.primary.main;
  const lightPurple = palette.light.main;

  const [search, setSearch] = useState('');

  const handleSearch = async () => {
    if (search) {
      const response = await fetch("https://orchidwiki-api-1eb2b332b486.herokuapp.com/orchids",
        {
          method: "GET",
          headers: { "content-type": "application/json" }
        }
      )
      const orchidData = await response.json()
      const searchedOrchids = orchidData.filter((item) => item.name.toLowerCase().includes(search))
      window.scrollTo({ top: 1800, left: 100, behavior: 'smooth' })

      setSearch('');
      setOrchids(searchedOrchids)
    }
  }

  const handleReset = async () => {
    const response = await fetch("https://orchidwiki-api-1eb2b332b486.herokuapp.com/orchids",
      {
        method: "GET",
        headers: { "content-type": "application/json" }
      }
    )
    const orchidData = await response.json()

    setSearch('');
    setOrchids(orchidData);
  }

  return (
    <Stack alignItems="center" ml="auto" mr="auto" justifyContent="center" width="85%" borderRadius="4px" bgcolor="#e7dcff"
      sx={{
        mt: {
          xl: "5em",
          xs: "-25px",
        },
        mb: {
          xl: '5em',
          xs: '3em'
        },
        border: 2,
        color: lightPurple
      }}
    >
      <Typography
        sx={{
          fontSize: {
            lg: '3rem',
            md: '2.5rem',
            xs: '1.75em'
          },
          mb: ".5em",
          mt: {
            xl: ".5em",
            xs: "1em"
          }
        }}
        fontFamily="Quicksand"
        fontWeight={500}
        align='center'
        color={mediumPurple}>
        Search OrchidWiki!
      </Typography>

      <TextField
        id='search'
        sx={{
          mb: "1em",
          mt: "1em",
          input: {
            borderBlockColor: mediumPurple,
            fontFamily: "Quicksand",
            fontSize: "1rem"
          },
          width: {
            xl: "50%",
            lg: "50%",
            md: "50%",
            sm: "50%",
            xs: "80%"
          },
          backgroundColor: '#fff',
          color: mediumPurple,
        }}
        value={search}
        onChange={(e) => setSearch(e.target.value.toLowerCase())}
        placeholder='Search OrchidWiki Database...'
        type='text'
      /><br />
      <Stack direction="row">
        <Button
          sx={{
            ml: '1em',
            mr: "1em",
            bgcolor: mediumPurple,
            color: '#fff',
            textTransform: 'none',

            width: {
              lg: '15em',
              md: "10em",
              sm: "6em",
              xs: '6em'
            },
            height: '3em',
            fontSize: "1rem",
            fontFamily: 'Quicksand',
            "&:hover": {
              bgcolor: 'rgba(179,136,255,0.85 )',
              color: '#fff'
            }
          }}
          onClick={handleSearch}
        // handle key press of enter
        >
          Search
        </Button>
        <Button
          sx={{
            ml: '.5em',
            mr: ".5em",
            mb: "2em",
            bgcolor: mediumPurple,
            color: '#fff',
            textTransform: 'none',
            width: {
              lg: '15em',
              md: "10em",
              sm: "6em",
              xs: '6em'
            },
            height: '3em',
            fontSize: "1rem",
            fontFamily: 'Quicksand',
            "&:hover": {
              bgcolor: 'rgba(179,136,255,0.85)',
              color: '#fff'
            }
          }}
          onClick={handleReset}
        >
          Reset
        </Button>
      </Stack>
    </Stack>
  )
}

export default SearchOrchids;

