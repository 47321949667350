import React, { useState, useEffect } from 'react';
import OrchidCard from './OrchidCard';
import { Stack, Pagination } from '@mui/material';

const Orchids = ({ orchids, setOrchids }) => {
    const getOrchidsData = async () => {
        const response = await fetch("https://orchidwiki-api-1eb2b332b486.herokuapp.com/orchids",
            {
                method: "GET",
                headers: { "content-type": "application/json" },
            }
        )
        const orchidData = await response.json();


        setOrchids(orchidData);
    };

    useEffect(() => {
        getOrchidsData();
    }, [])

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [orchidsPerPage] = useState(8);

    const indexOfLastOrchid = currentPage * orchidsPerPage;
    const indexOfFirstOrchid = indexOfLastOrchid - orchidsPerPage;
    const currentOrchids = orchids.slice(indexOfFirstOrchid, indexOfLastOrchid);

    const paginate = (event, value) => {
        setCurrentPage(value);

        window.scrollTo({ top: 1800, behavior: "smooth" });
    }

    return (
        <>
            <Stack direction={{ xs: 'column', sm: 'row' }} useFlexGap flexWrap="wrap" justifyContent="center">
                {currentOrchids.map((orchid, idx) => (
                    <item>
                        <OrchidCard key={idx} orchid={orchid} />
                    </item>
                ))}
            </Stack>

            <Stack sx={{ mt: '2em', mb: '2em'}} alignItems="center">
                {orchids.length > 9 && (
                    <Pagination
                        color='standard'
                        shape='rounded'
                        defaultPage={1}
                        count={Math.ceil(orchids.length / orchidsPerPage)}
                        page={currentPage}
                        onChange={paginate}
                        size='large'
                    />
                )}
            </Stack>
        </>
    )
}

export default Orchids