import React, { useState } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { CssBaseline, ThemeProvider, responsiveFontSizes } from "@mui/material";
import { app } from "./firebaseConfig"
import { browserSessionPersistence, confirmPasswordReset, createUserWithEmailAndPassword, getAuth, sendPasswordResetEmail, setPersistence, signInWithEmailAndPassword } from 'firebase/auth';
import './App.css';
import Home from './pages/Home';
import Blog from './pages/Blog';
import About from './pages/About';
import Login from './pages/Login/Login';
import Register from './pages/Login/Register';
import Profile from './pages/Profile';
import Contribute from './pages/Contribute';
import Logout from './components/Logout';
import ForgotPassword from './pages/Login/ForgotPassword';
import ResetPassword from './pages/Login/ResetPassword';

function App() {
  let theme = useTheme();
  theme = responsiveFontSizes(theme);
  let navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  function useQuery() {
    const location = useLocation();
    return new URLSearchParams(location.search);
  };
  const query = useQuery();
  const oobCode = query.get('oobCode');
  const loggedIn = sessionStorage.getItem('Auth Token')

  const handleAuthAction = (id) => {
    const auth = getAuth(app);
    setPersistence(auth, browserSessionPersistence);
    if (id === 1) {
      signInWithEmailAndPassword(auth, email, password)
        .then((response) => {
          navigate('/')
          sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken)
        }).catch((error) => {
          if (error.code === 'auth/wrong-password') {
            // toast.error('The password you entered is incorrect.');
            console.log("error")
          }
          if (error.code === 'auth/user-not-found') {
            // toast.error('The email you entered is not registered.');
            console.log("error")
          }
        })
    }
    if (id === 2) {
      createUserWithEmailAndPassword(auth, email, password)
        .then((response) => {
          navigate('/login');
          // toast.success("Successfully registered account. You may now login.");
        }).catch((error) => {
          if (error.code === 'auth/email-already-in-use') {
            // toast.error('The email address you entered is already registered.');
            console.log("error")
          }
          if (error.code === 400) {
            console.log("error")
          }
        })
    }
    if (id === 3) {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          // toast.success("Check your email for password reset link.")
          console.log("error")
        }).catch((error) => {
          if (error.code === 'auth/user-not-found') {
            // toast.error('The email you entered is not registered.');
            console.log("error")
          }
        })
    }
    if (id === 4) {
      confirmPasswordReset(auth, oobCode, password)
        .then(() => {
          // toast.success("You have successfully reset your password, you can now login.");
          navigate('/login');
        }).catch((error) => {
          if (error) {
            // toast.error('Password must be at least six characters in length.');
            console.log("error")
          }
        })
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className='app'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login setEmail={setEmail} setPassword={setPassword} handleAction={() => handleAuthAction(1)} />} />
          <Route path='/register' element={<Register setEmail={setEmail} setPassword={setPassword} handleAction={() => handleAuthAction(2)} />} />
          <Route path='/forgotpassword' element={<ForgotPassword setEmail={setEmail} handleAction={() => handleAuthAction(3)} />} />
          <Route path='/resetpassword' element={<ResetPassword setPassword={setPassword} handleAction={() => handleAuthAction(4)} />} />
          <Route path='/home' element={<Home />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/about' element={<About />} />
          <Route path='/contribute' element={loggedIn ? <Contribute /> : <Navigate to="/login" />} />
          <Route path='/profile' element={loggedIn ? <Profile /> : <Navigate to="/login" />} />
          <Route path='/logout' element={<Logout />} />
        </Routes>
        {/* <ToastContainer /> */}
      </div>
    </ThemeProvider>
  );
}

export default App;
