import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
// import { toast } from 'react-toastify';

const Logout = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  useEffect(() => {
    sessionStorage.clear('Auth Token')
    signOut(auth)
      .then(() => {
        navigate("/", { replace: true })
        // toast.success("You have successfuly logged out.")
        console.log("error")
      });
  }, []);

}

export default Logout