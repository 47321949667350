import * as React from 'react';
import { Box, Stack } from '@mui/material';
import { TextField, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';


const RegisterForm = ({ setEmail, setPassword, handleAction }) => {
    return (
        <>
            <Box
                display="flex"
                flexDirection='column'
                component="form"
                autoComplete='on'
                width='100%'
                height='100%'
            >
                <TextField
                    id='email'
                    label='Email Address'
                    variant='outlined'
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{
                        m: '1.5ch 3ch'
                    }}
                />
                <TextField
                    id='password'
                    label='Password'
                    type='password'
                    variant='outlined'
                    onChange={(e) => setPassword(e.target.value)}
                    sx={{
                        m: '1.5ch 3ch',
                    }}

                />
            </Box>
            <Button
                display="flex"
                variant='contained'
                sx={{
                    width: "75%",
                    textTransform: "none",
                    fontFamily: "Quicksand",
                    fontSize: "1rem",
                    color: '#fff',
                    ml: 'auto',
                    mr: 'auto',
                    mt: '2ch',
                    mb: '2ch',
                    "&:hover": {
                        bgcolor: 'rgba(179,136,255,0.85)',
                        color: '#fff'
                    }
                }}
                onClick={handleAction}
            >
                Create Account
            </Button>
            <Stack backgroundColor="#e8eef1" mt="2ch">
                <Typography
                    m="2ch"
                    fontFamily="Quicksand"
                    align='center'
                    fontSize="1rem"
                >
                    Already a member? <NavLink className="formLink" to="/login">Login</NavLink>
                </Typography>

            </Stack>
        </>
    )
}

export default RegisterForm;