import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            // medium purple '#b388ff'
            main: '#8463be',
        },
        secondary: {
            //  light pink '#f1dafb'
            main: '#f1dafb',
        },
        accent: {
            // light pastel green - green A100(#b9f6ca) '#a577dd'
            main: '#b9f6ca',
        },
        light: {
            main: "#e7dcff" , //light purple
        },
        dark: {
            main: '#8463be' //dark purple
        }
    },
    typography: {
        fontFamily: ["Open Sans", "sans-serif"]
    }
});

export default theme;

  