import React from 'react';
import { NavLink } from 'react-router-dom';
import { Typography, Stack } from '@mui/material';
import { useTheme } from '@emotion/react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import SubmitOrchidForm from '../components/SubmitOrchidForm';

const Contribute = () => {
  const { palette } = useTheme();
  const mediumPurple = palette.primary.main;

  return (
    <>
      <Navbar />
      <Stack
        alignItems="center"
        justifyContent="center"
        bgcolor={mediumPurple}
        ml='auto'
        mr='auto'
        height="100"
        width="100%"
      >
        <Typography
          mb="2%"
          mt="1em"
          fontFamily="Quicksand"
          color="#fff"
          align='center'
          sx={{
            fontSize: {
              xl: "3em",
              md: "2.5em",
              xs: "2em",
            }
          }}
        >
          Contribute To The OrchidWiki Database
        </Typography>
        <Typography sx={{
          m: "1em",
          fontSize: {
            xl: '1.5em',
            md: '1.5em',
            xs: '1em'
          }
        }}
          fontFamily="Quicksand"
          color={'#fff'}
          fontWeight={500}
          align='center'>
          Use the form below to contribute to the OrchidWiki database. <br /> Make sure the orchid you want to add isn't <NavLink className="darkbg-link" to="/home">already in the database.</NavLink> <br />All fields are required.
        </Typography>

        {/* SUBMIT FORM */}
        <Stack
          display="flex"
          bgcolor="#fff"
          mt='4ch'
          mb='4ch'
          sx={{
            width: {
              xl: "75%",
              xs: "95%"
            },
          }}
        >
          <Typography
            mt="2ch"
            mb="0ch"
            fontSize="1.5em"
            fontFamily="Quicksand"
            color={mediumPurple}
            align='center'>

          </Typography>
          <SubmitOrchidForm />
        </Stack>
        <Typography
          fontFamily="Quicksand"
          fontSize="2em"
          fontWeight={500}
          color="#fff"
          mb="20ch"
        >
          Thanks for contributing to OrchidWiki!
        </Typography>
      </Stack>
      <Footer />
    </>
  )
}

export default Contribute;