import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const About = () => {
  const { palette } = useTheme();
  const deepPurple = palette.primary.main;
  const lightPurple = palette.secondary.main;

  return (
    <>
      <Stack
        bgcolor={deepPurple}
        width="100%"
        sx={{
          height: {
            xl: "300vh",
            xs: "275vh"
          }
        }}
      >
        <Navbar />
        {/* LIGHT PURPLE PAGE HEADER - ABOUT */}
        <Box
          mt="4ch"
          marginLeft='auto'
          marginRight='auto'
          color={deepPurple}
          bgcolor={lightPurple}
          width="25%"
          borderRadius={2}
        >
          <Typography
            align='center'
            fontWeight='500'
            fontFamily='Quicksand'
            sx={{
              fontSize: {
                xl: '4em',
                xs: '2em'
              },
            }}
          >
            About
          </Typography>
        </Box>
        {/* IMAGE AND HERO TEXT */}
        <img className='image' src='../assets/2.png' alt='Green Cattleya Hybrid' loading='lazy' />
        <Typography
          fontFamily='Quicksand'
          color={'#fff'}
          align='center'
          mb="2ch"
          sx={{
            fontSize: {
              xl: '3em',
              xs: '1.25em'
            },
          }}
        >
          OrchidWiki is an online database <br /> of orchid species and hybrids.
        </Typography>
        {/* FAQ SECTION */}
        <Stack
          mt='5ch'
          ml='auto'
          mr='auto'
          width="75%"
          bgcolor={'#fff'}
          sx={{
            border: 4,
            borderColor: lightPurple,
            borderRadius: 2,
          }}
        >
          <Typography
            color={deepPurple}
            align='center'
            fontFamily='Quicksand'
            fontWeight='500'
            mb="1ch"
            mt="4ch"
            ml="2ch"
            mr="2ch"
            sx={{
              fontSize: {
                xl: '2.25em',
                xs: '1.2em'
              }
            }}
          >
            What is a 'Wiki'?
          </Typography>
          <Typography
            ml="2ch"
            mr="2ch"
            align='center'
            fontFamily='Quicksand'
            sx={{
              fontSize: {
                xl: '2em',
                xs: '1em'
              }
            }}
          >
            1. A wiki is a collaborative website whose content can be edited by anyone who has access to it.<br />
            2. A collaborative website which can be directly edited merely using a web browser, often by anyone with access to it.
          </Typography>
          <Typography
            color={deepPurple}
            align='center'
            fontFamily='Quicksand'
            fontWeight='500'
            mb="1ch"
            mt="4ch"
            ml="2ch"
            mr="2ch"
            sx={{
              fontSize: {
                xl: '2.25em',
                xs: '1.2em'
              }
            }}
          >
            How can I contribute my knowledge to OrchidWiki?
          </Typography>
          <Typography
            ml="2ch"
            mr="2ch"
            align='center'
            fontFamily='Quicksand'
            sx={{
              fontSize: {
                xl: '2em',
                xs: '1em'
              }
            }}
          >
            1. Create a free account by visiting the <NavLink className='lightbg-link' to='/register'>Register </NavLink>  page.<br />
            2. Make sure you're logged in and head over to our <NavLink className='lightbg-link' to='/submit'>Submit An Orchid</NavLink> page.
          </Typography>
          <Typography
            color={deepPurple}
            align='center'
            fontFamily='Quicksand'
            fontWeight='500'
            mb="1ch"
            mt="4ch"
            ml="2ch"
            mr="2ch"
            sx={{
              fontSize: {
                xl: '2.25em',
                xs: '1.2em'
              }
            }}
          >
            Aren't there other sites like this? What makes OrchidWiki different?
          </Typography>
          <Typography
            ml="2ch"
            mr="2ch"
            align='center'
            fontFamily='Quicksand'
            sx={{
              fontSize: {
                xl: '2em',
                xs: '1em'
              }
            }}
          >
            We created OrchidWiki because we wanted an orchid catalogue that was simple, clean, and easy to navigate. Mostly, we wanted a site that was optimized for use on a mobile device or tablet.
            <br /> <br />
            With OrchidWiki, you can look up an orchid while you are outside in your garden or greenhouse (or anywhere for that matter) as long as you have your phone with you. The information is displayed in an easy to read format.
          </Typography>
          <Typography
            color={deepPurple}
            align='center'
            fontFamily='Quicksand'
            fontWeight='500'
            mb="1ch"
            mt="4ch"
            ml="2ch"
            mr="2ch"
            sx={{
              fontSize: {
                xl: '2.25em',
                xs: '1.2em'
              }
            }}
          >
            I grow orchids and make my own hybrids, can I add my orchid to OrchidWiki?
          </Typography>
          <Typography
            ml="2ch"
            mr="2ch"
            align='center'
            fontFamily='Quicksand'
            sx={{
              fontSize: {
                xl: '2em',
                xs: '1em'
              }
            }}
          >
            We hope that orchid growers and hybridizers around the world will utilize OrchidWiki. We wanted to make a super simple way to catalogue both species and hybrids which may have unique or complex growing conditions.
          </Typography>
          <Typography
            color={deepPurple}
            align='center'
            fontFamily='Quicksand'
            fontWeight='500'
            mb="1ch"
            mt="4ch"
            ml="2ch"
            mr="2ch"
            sx={{
              fontSize: {
                xl: '2.25em',
                xs: '1.2em'
              }
            }}
          >
            Contact Us
          </Typography>
          <Typography
            ml="2ch"
            mr="2ch"
            mb="4ch"
            align='center'
            fontFamily='Quicksand'
            sx={{
              fontSize: {
                xl: '2em',
                xs: '1em'
              }
            }}
          >
            If you have any questions, comments, or concerns you can contact us by <NavLink className='lightbg-link' to="mailto: odysseyorchids@gmail.com"> email</NavLink>.
          </Typography>
        </Stack>

      </Stack>
      <Footer />
    </>
  )
}

export default About;