import * as React from 'react';
import { Button, Box, Icon, Stack, TextField, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google';


const LoginForm = ({ setEmail, setPassword, handleAction }) => {
    // const auth = getAuth();
    // function signInWithGoogle() {
    //     const provider = new GoogleAuthProvider();
    //     return signInWithPopup(auth, provider)
    // }

    return (
        <>
            <Box
                display="flex"
                flexDirection='column'
                component="form"
                autoComplete='on'
                width='100%'
                height='100%'
            >
                <TextField
                    id='email'
                    label='Email Address'
                    variant='outlined'
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{
                        m: '1.5ch 3ch'
                    }}
                />
                <TextField
                    id='password'
                    label='Password'
                    type='password'
                    variant='outlined'
                    onChange={(e) => setPassword(e.target.value)}
                    sx={{
                        m: '1.5ch 3ch',
                    }}

                />
            </Box>
            <Button
                // display="flex"
                variant='contained'
                sx={{
                    width: "75%",
                    textTransform: "none",
                    fontFamily: "Quicksand",
                    fontSize: "1rem",
                    color: '#fff',
                    ml: 'auto',
                    mr: 'auto',
                    mt: '2ch',
                    mb: '2ch',
                    "&:hover": {
                        bgcolor: 'rgba(179,136,255,0.85)',
                        color: '#fff'
                    }
                }}
                onClick={handleAction}
            >
                Login
            </Button>
            <Typography
                fontFamily="Quicksand"
                align='center'
                fontSize="1rem"
            >
                Or
            </Typography>
            <Button
                variant='contained'
                onClick={handleAction}
                sx={{
                    width: "75%",
                    textTransform: "none",
                    fontFamily: "Quicksand",
                    fontSize: "1rem",
                    color: '#fff',
                    mt: "2ch",
                    mb: "2ch",
                    ml: 'auto',
                    mr: 'auto',
                    "&:hover": {
                        bgcolor: 'rgba(179,136,255,0.85)',
                        color: '#fff'
                    }
                }}
            >
                <Icon sx={{ mr: "5px", mb: "5px", fontSize: "x-large" }}>
                    <GoogleIcon />
                </Icon>
                Sign In With Google
            </Button>
            <Stack backgroundColor="#e8eef1" mt="2ch">
                <Typography
                    m="2ch"
                    fontFamily="Quicksand"
                    align='center'
                    fontSize="1rem"
                >
                    Or <NavLink className="formLink" to="/register">Sign Up For An Account.</NavLink>
                </Typography>
            </Stack>
        </>
    )
}

export default LoginForm;