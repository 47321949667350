import React, { useState } from 'react';
import { Box } from '@mui/material';
import Navbar from '../components/Navbar';
import Orchids from '../components/Orchids';
import SearchOrchids from '../components/SearchOrchids';
import Hero from '../components/Hero';
import Footer from '../components/Footer';

const Home = () => {
  const [orchids, setOrchids] = useState([]);

  return (
    <Box>
      <Navbar />
      <Hero />
      <SearchOrchids setOrchids={setOrchids}/>     
      <Orchids
        setOrchids={setOrchids}
        orchids={orchids}
      />
      <Footer/>
    </Box>

  )
}

export default Home