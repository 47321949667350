import React from 'react'
import { Button, Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { NavLink } from 'react-router-dom';

const Hero = () => {
  const { palette } = useTheme();
  const deepPurple = palette.primary.main;
  const lightPurple = palette.light.main;

  const handleScrollTo = () => {
    const element = document.getElementById('search');
    if (element) {
      element.scrollIntoView({ top: element, behavior: 'smooth' });
    }
  };
  // item xs={12} sm={6} md={6} xl={12}
  return (
    <Box mb='10ch'>
      <Grid container direction='row'>
        <Grid item xs={12} sm={12} md={12} xl={12} bgcolor={lightPurple}>
          <img className='mobile-hero-image' src='../assets/1.png' alt='pink phalaenopsis orchid flower' loading='lazy' />
          <Typography
            fontFamily="Handlee"
            variant='h2'
            color={deepPurple}
            align='center'
            mb="1ch"
            mt="2ch"
            ml="auto"
            mr="auto"
          >
            Welcome to OrchidWiki!
          </Typography>
          <Typography
            fontFamily="Quicksand"
            fontWeight={500}
            color={deepPurple}
            align='center'
            mb="3ch"
            mt="3ch"
            ml="1ch"
            mr="1ch"
            sx={{
              fontSize: {
                xl: '2em',
                lg: '1.75em',
                md: '1.5em',
                sm: '1.25em',
                xs: '1.15em'
              },
            }}
          >
            OrchidWiki is an online database for orchid species and hybrids.
            <br /><br />
            Search for any orchid by name or alphabetically!
            <br />
            <br />
            Want to contribute to OrchidWiki? Sign up for an account <NavLink to='/register' className='lightbg-link'>here</NavLink>.
          </Typography>
          <Button
            sx={{
              display: "block",
              textTransform: 'none',
              bgcolor: '#8463be',
              color: "#fff",
              width: {
                xl: "25%",
                xs: "65%"
              },
              height: "auto",
              ml: "auto",
              mr: "auto",
              mb: {
                xl: '9ch',
                md: '8ch',
                sm: '8ch',
                xs: '8ch'
              },
              "&:hover": {
                bgcolor: "#6e529e",
                color: "#fff"
              },
              fontFamily: "Quicksand",
              fontSize: {
                xl: '2em',
                lg: '1.75em',
                md: '1.5em',
                sm: '1.25em',
                xs: '1.25em'
              },
            }}
            onClick={handleScrollTo}
          >
            Search Orchids Now
          </Button>
        </Grid>
        {/* <Grid item xl={6} lg={6} md={6} sm={6} xs={6} bgcolor={lightPurple} justifyContent='center'> */}
          {/* <img className='hero-image' src='../assets/1.png' alt='pink phalaenopsis orchid flower' loading='lazy' /> */}
        {/* </Grid> */}
      </Grid>

    </Box>
  )
}

export default Hero;