import React from 'react';
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, TextField } from "@mui/material";
// import { toast } from 'react-toastify';

const SubmitOrchidForm = () => {
    const orchidSchema = yup.object({
        name: yup.string().required(),
        description: yup.string().required("Required"),
        speciesOrHybrid: yup.string().required("Required"),
        distribution: yup.string().required("Required"),
        temperature: yup.string().required("Required"),
        light: yup.string().required("Required"),
        waterAndHumid: yup.string().required("Required"),
        fertilizer: yup.string().required("Required"),
        potting: yup.string().required("Required"),
        author: yup.string().required("Required"),
        imgUrl: yup.string().required("Required")
    });

    const initialValuesOrchid = {
        name: "",
        description: "",
        speciesOrHybrid: "",
        distribution: "",
        temperature: "",
        light: "",
        waterAndHumid: "",
        fertilizer: "",
        potting: "",
        author: "",
        imgUrl: ""
    }

    const handleFormSubmit = async (values, onSubmitProps) => {
        await fetch("https://orchidwiki-api-1eb2b332b486.herokuapp.com/post",
            {
                method: "POST",
                headers: { "content-type": "application/json" },
                body: JSON.stringify(values)
            }
        );
        onSubmitProps.resetForm();
        // toast.success("Thank you for your submission.")
    }

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesOrchid}
            validationSchema={orchidSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="1em"
                        m="2ch"
                    >
                        <>  
                            <TextField
                                label="Name of Orchid"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                                name='name'                             
                                error={
                                    Boolean(touched.name) && Boolean(errors.name)
                                }
                                helperText={touched.name && errors.name}
                                multiline={true}  
                                rows={1}
                            />
                            <TextField
                                label="Description"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.description}
                                name='description'
                                error={
                                    Boolean(touched.description) && Boolean(errors.description)
                                }
                                helperText={touched.description && errors.description}
                                multiline={true}
                                rows={8}
                            />
                            <TextField
                                label="Species or Hybrid?"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.speciesOrHybrid}
                                name='speciesOrHybrid'
                                error={
                                    Boolean(touched.speciesOrHybrid) && Boolean(errors.speciesOrHybrid)
                                }
                                helperText={touched.speciesOrHybrid && errors.speciesOrHybrid}
                                multiline={true}
                                rows={1}
                            />
                            <TextField
                                label="Distribution"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.distribution}
                                name='distribution'
                                error={
                                    Boolean(touched.distribution) && Boolean(errors.distribution)
                                }
                                helperText={touched.distribution && errors.distribution}
                                multiline={true}
                                rows={6}
                            />
                            <TextField
                                label="Temperature"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.temperature}
                                name='temperature'
                                error={
                                    Boolean(touched.temperature) && Boolean(errors.temperature)
                                }
                                helperText={touched.temperature && errors.temperature}
                                multiline={true}
                                rows={6}
                            />
                            <TextField
                                label="Light"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.light}
                                name='light'
                                error={
                                    Boolean(touched.light) && Boolean(errors.light)
                                }
                                helperText={touched.light && errors.light}
                                multiline={true}
                                rows={6}
                            />
                            <TextField
                                label="Water and Humidity"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.waterAndHumid}
                                name='waterAndHumid'
                                error={
                                    Boolean(touched.waterAndHumid) && Boolean(errors.waterAndHumid)
                                }
                                helperText={touched.waterAndHumid && errors.waterAndHumid}
                                multiline={true}
                                rows={6}
                            />
                            <TextField
                                label="Fertilizer"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.fertilizer}
                                name='fertilizer'
                                error={
                                    Boolean(touched.fertilizer) && Boolean(errors.fertilizer)
                                }
                                helperText={touched.fertilizer && errors.fertilizer}
                                multiline={true}
                                rows={6}
                            />
                            <TextField
                                label="Potting"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.potting}
                                name='potting'
                                error={
                                    Boolean(touched.potting) && Boolean(errors.potting)
                                }
                                helperText={touched.potting && errors.potting}
                                multiline={true}
                                rows={6}
                            />
                            <TextField
                                label="Author"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.author}
                                name='author'
                                error={
                                    Boolean(touched.author) && Boolean(errors.author)
                                }
                                helperText={touched.author && errors.author}
                                multiline={true}
                                rows={1}
                            />
                            <TextField
                                label="Image URL"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.imgUrl}
                                name='imgUrl'
                                error={
                                    Boolean(touched.imgUrl) && Boolean(errors.imgUrl)
                                }
                                helperText={touched.imgUrl && errors.imgUrl}
                                multiline={true}
                                rows={1}
                            />
                        </>
                        <Button
                            type="submit"
                            display="flex"
                            variant='contained'
                            sx={{
                                textTransform: "none",
                                fontFamily: "Quicksand",
                                fontSize: "1rem",
                                color: '#fff',
                                ml: 'auto',
                                mr: 'auto',
                                mt: '2ch',
                                mb: '2ch',
                                "&:hover": {
                                    bgcolor: 'rgba(179,136,255,0.8)',
                                    color: '#fff'
                                }
                            }}>
                            Submit
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    )
}

export default SubmitOrchidForm