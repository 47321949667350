import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';

const Footer = () => {
    const { palette } = useTheme();
    const deepPurple = palette.primary.main;
    const year = new Date().getFullYear();

    const pages = ['Home', 'Contribute', 'Blog', 'About'];

    const authToken = sessionStorage.getItem('Auth Token');

    if (authToken) {
        pages.push('Profile');
        pages.push('Logout');
    } else {
        pages.push('Login');
    }

    return (
        <>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                sx={{
                    width: "100%",
                    height: "45vh",
                    mb: "0",
                    backgroundImage: "url(./assets/wavebanner.png)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                }}
            >
                <Typography
                    fontFamily="Handlee"
                    fontWeight={600}
                    color={deepPurple}
                    align='center'
                    sx={{
                        fontSize: {
                            xl: "3em",
                            xs: "2em",
                        },
                        mt: {
                            xl: "1.5em",
                            xs: "2.5em"
                        }
                    }}
                >
                    OrchidWiki
                </Typography>
                <Stack
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    justifyContent="center"
                    m="2ch"
                >
                    {pages.map((page) => (
                        <Typography
                            fontSize="1em"
                            fontWeight={600}
                            m="2ch"
                            align='center'
                        >
                            <NavLink
                                className="lightbg-link"
                                to={`/${page}`}>{page}
                            </NavLink>
                        </Typography>
                    ))}
                </Stack>
                <Stack
                    direction="row"
                    p="1em"
                    ml="auto"
                    mr="auto"
                    mt="1em"
                >
                    <NavLink to="https://www.facebook.com">
                        <Avatar alt='Facebook' src="./assets/color_fb_logo_icon.svg" variant='square' sx={{ width: 28, height: 28, ml: "1em", mr: "1em" }} />
                    </NavLink>
                    <NavLink to="https://www.instagram.com">
                        <Avatar alt='Instagram' src="./assets/color_ig_logo_icon.svg" variant='square' sx={{ width: 28, height: 28, ml: "1em", mr: "1em" }} />
                    </NavLink>
                </Stack>
                <Typography
                    fontFamily="Open Sans"
                    color={deepPurple}
                    fontSize=".85em"
                    fontWeight={500}
                    align="center"
                    mt="4em"
                >
                    © {year} OrchidWiki.com
                </Typography>
            </Box>
        </>
    )
}

export default Footer