import { Button, Card } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@emotion/react';
import Box from '@mui/material/Box';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const OrchidCard = ({ orchid }) => {
  const { palette } = useTheme();
  const deepPurple = palette.primary.main;

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded)
  };

  return (
    <Box sx={{ m: { lg: "2em", xs: "1em" }, }}>
      <Card sx={{ minWidth: { lg: 345, xs: 330 }, maxWidth: { lg: 345, xs: 340 }, m: "auto" }} >
        <CardContent sx={{ background: deepPurple }}>
          <Typography color='#fff' gutterBottom variant='h5' fontFamily="Quicksand" fontWeight="500" align='center' >
            {orchid.name}
          </Typography>
        </CardContent>
        <CardMedia
          sx={{ height: 250 }}
          image={orchid.imgUrl}
          title={orchid.name}
        />
        <CardActions>
          <Button onClick={handleExpandClick} sx={{ fontFamily: "Quicksand", textTransform: "none" }}>Read Culture Information
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="Learn More"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </Button>
        </CardActions>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography fontWeight="bold" fontFamily="Quicksand">Description</Typography>
            <Typography paragraph fontFamily="Quicksand">{orchid.description}</Typography>
            <Typography fontWeight="bold" fontFamily="Quicksand">Distribution</Typography>
            <Typography paragraph fontFamily="Quicksand">{orchid.distribution}</Typography>
            <Typography fontWeight="bold" fontFamily="Quicksand">Temperature</Typography>
            <Typography paragraph fontFamily="Quicksand">{orchid.temperature}</Typography>
            <Typography fontWeight="bold" fontFamily="Quicksand">Light</Typography>
            <Typography paragraph fontFamily="Quicksand">{orchid.light}</Typography>
            <Typography fontWeight="bold" fontFamily="Quicksand">Water and Humidity</Typography>
            <Typography paragraph fontFamily="Quicksand">{orchid.waterAndHumid}</Typography>
            <Typography fontWeight="bold" fontFamily="Quicksand">Fertilizer</Typography>
            <Typography paragraph fontFamily="Quicksand">{orchid.fertilizer}</Typography>
            <Typography fontWeight="bold" fontFamily="Quicksand">Potting</Typography>
            <Typography paragraph fontFamily="Quicksand">{orchid.potting}</Typography>
          </CardContent>
        </Collapse>
      </Card>
    </Box>
  )
}

export default OrchidCard

