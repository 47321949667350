import * as React from 'react';
import { Typography, Stack } from '@mui/material';
import { useTheme } from '@emotion/react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPassword = ({ setPassword, handleAction }) => {
    const { palette } = useTheme();
    const deepPurple = palette.primary.main;
    const lightGreen = palette.accent.main;



    return (
        <>
            <Navbar />
            <Stack
                alignItems="center"
                justifyContent="center"
                bgcolor={deepPurple}
                ml='auto'
                mr='auto'
                height="100"
                width="100%"
            >
                <Typography sx={{
                    mt: '2ch',
                    mb: '1ch',
                    fontSize: '3em',
                }}
                    fontFamily="Handlee"
                    color={lightGreen}
                    align='center'
                    fontWeight={600}
                >
                    OrchidWiki
                </Typography>
                {/* FORM */}
                <Stack
                    display="flex"
                    bgcolor="#fff"
                    mt='4ch'
                    mb='4ch'
                    sx={{
                        width: "350px",
                        height: "350px",
                    }}
                >
                    <Typography
                        mt="2ch"
                        mb="0ch"
                        fontSize="1.5em"
                        fontFamily="Quicksand"
                        fontWeight={500}
                        color={deepPurple}
                        align='center'>
                        Reset Password
                    </Typography>
                    <Typography
                        mt="2ch"
                        mb="2ch"
                        ml="4ch"
                        mr="4ch"
                        fontFamily="Quicksand"
                        align='center'>
                        Enter your new password below. Password must be at least six characters in length.
                    </Typography>
                    <ResetPasswordForm
                        setPassword={setPassword}
                        handleAction={handleAction}
                    />
                </Stack>
                <Typography
                    fontFamily="Quicksand"
                    fontSize="1rem"
                    color="#fff"
                    mb="5em"
                    fontWeight={500}
                >

                </Typography>
            </Stack>
            <Footer />
        </>
    )
}

export default ResetPassword;