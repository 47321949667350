import * as React from 'react';
import Navbar from '../components/Navbar';

const Profile = () => {
  return (
    <>
    <Navbar/>
    </>

  )
}

export default Profile;