// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';

// import { getAnalytics } from "firebase/analytics";     

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyAo1XsAgEiHE5zzUCi8cpH1fZylasuca2I",

  authDomain: "odysseyorchids-f6382.firebaseapp.com",

  projectId: "odysseyorchids-f6382",

  storageBucket: "odysseyorchids-f6382.appspot.com",

  messagingSenderId: "275439745244",

  appId: "1:275439745244:web:eb62fb10e8bca785391c19",

  measurementId: "G-YQKBNXGDP3"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// const analytics = getAnalytics(app);
