import * as React from 'react';
import { Box } from '@mui/material';
import Navbar from '../components/Navbar';

const Blog = () => {
  return (
    <Box>
      <Navbar />
    </Box>
  )
}

export default Blog