import * as React from 'react';
import { Box, Stack } from '@mui/material';
import { TextField, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';


const ForgotPasswordForm = ({ setEmail, handleAction }) => {
    return (
        <>
            <Box
                display="flex"
                flexDirection='column'
                component="form"
                autoComplete='on'
                width='100%'
                height='100%'
            >
                <TextField
                    id='email'
                    label='Email Address'
                    variant='outlined'
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{
                        ml: '3ch',
                        mr: '3ch'
                    }}


                />
            </Box>
            <Button
                display="flex"
                variant='contained'
                sx={{
                    width: "75%",
                    textTransform: "none",
                    fontFamily: "Quicksand",
                    fontSize: "1rem",
                    color: '#fff',
                    ml: 'auto',
                    mr: 'auto',
                    mt: '2ch',
                    mb: '1ch',
                    "&:hover": {
                        bgcolor: 'rgba(179,136,255,0.85)',
                        color: '#fff'
                    }
                }}
                onClick={handleAction}
            >
                Send Reset Link
            </Button>
            <Stack backgroundColor="#e8eef1" mt="2ch">
                <Typography
                    m="2ch"
                    fontFamily="Quicksand"
                    align='center'
                    fontSize="1rem"
                >
                    <NavLink className="formLink" to="/login">Login</NavLink> Or <NavLink className="formLink" to="/register">Sign Up For An Account.</NavLink>
                </Typography>

            </Stack>
        </>
    )
}

export default ForgotPasswordForm;